


















































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "SignIn",
  data: () => ({
    showPW: false,
    username: "",
    password: "",
    validationErr: "",
  }),
  methods: {
    ...mapActions(["login"]),
    // clear text fields
    clearForm() {
      this.username = "";
      this.password = "";
    },
    signIn() {
        let user = {
          username: this.username,
          password: this.password,
        };
        this.login(user);
        this.clearForm();
    },
  },
  computed: mapGetters(["error"]),
});

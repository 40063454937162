









import Vue from "vue";
import Navbar from "./components/navbar/Navbar.vue";

export default Vue.extend({
  name: "App",
  components: {
    Navbar,
  },
  // sets tab title after rendering the app
  mounted() {
    document.title="acaPot-IoT"
  }
});

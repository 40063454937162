



















































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "projectInfo",
  computed: mapGetters(["acapotdata"]),
  methods: {
    refreshPage() {
      location.reload();
    },
  },
  data() {
    return {
      chipItems: [
        { name: "Echtzeit-Kaffeedaten", icon:"coffee" },
        { name: "Benutzerauthentifizierung", icon:"person" },
        { name: "Kontozähleraktualisierung per NFC-Karte & Button", icon:"add_circle" },
        { name: "Übersicht des eigenen Kaffeekonsums", icon:"dashboard" },
      ],
    };
  },
});

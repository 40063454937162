























































import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "CoffeeCard",
  data: () => ({
    //
  }),
  computed: mapGetters(["acapotdata", "potAwayTime"]),
  props: [
    "title",
    "value",
    "maxValue",
    "valuePercent",
    "description",
    "age_min",
  ],
});

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify); 

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: colors.brown.darken1, 
            secondary: colors.brown.lighten4, 
            accent: colors.indigo.base, 
          },
        },
      },
      icons: {
        iconfont: 'md',
      },
});










































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "SignUp",

  data: () => ({
    username: "",
    uid: "",
    password: "",
    passwordConfirm: "",
    showPW: false,
    validationErr: "",
  }),
  methods: {
    // clear data from sign up form
    clearForm() {
      this.username = "";
      this.uid = "";
      this.password = "";
      this.passwordConfirm = "";
    },
    //imports register function from vuex store
    ...mapActions(["register"]),
    signUp() {
      let user = {
        username: this.username,
        password: this.password,
        passwordConfirm: this.passwordConfirm,
        uid: this.uid,
      };
      this.register(user);
      this.clearForm();
    },
  },
  computed: mapGetters(["error", "success"]),
});

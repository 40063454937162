















































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "CoffeeCounterStatistic",
  data: () => ({
    headers: [
      { text: "Datum des Eintrags", value: "timestamp"},
      { text: "Button oder NFC", value: "method"},
      { text: "Eintrag löschen", value: "edit", sortable: false },
    ],
    dialogDelete: false,
    editedItem: {
      username: "",
      timestamp: "",
      method: "",
      _id: 0,
    },
  }),
  computed: mapGetters(["myCoffeeCounterData", "success", "error"]),
  methods: {
    // get background color of entry in data table
    getColor(method: string) {
      if (method == "Button") return "blue";
      else if (method == "NFC") return "green";
    },
    ...mapActions(["deleteCoffeeCounter"]),
    // delete coffee counter item
    deleteItem(item: any) {
      this.dialogDelete = true;
      this.editedItem = item;
    },
    // open up confirmation modal to delete coffee counter item 
    deleteItemConfirm() {
      console.log(this.editedItem._id);
      this.deleteCoffeeCounter(this.editedItem._id);
      this.closeDelete();
    },
    // close delete modal
    closeDelete() {
      this.dialogDelete = false;
    },
  },
});
  
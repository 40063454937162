import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    user: {
      username: "",
      uid: [],
      id: Number,
    },
    error: "",
    success: "",
    acapotdata: {
      coffee: {
        age_min: 0,
        cups_150ml: 0,
        isPotOnScale: Boolean,
        liters: 0,
        potAwayTime_sec: 0,
      },
      ok: Boolean,
      scale: {
        brutto_kg: 0,
        netto_kg: 0,
      },
    },
    coffeeCounterData: [
      {
        _id: "",
        username: "",
        uid: [],
        timestamp: Date,
        method: "",
        __v: 0,
      },
    ],
  },
  getters: {
    user: (state) => state.user,
    error: (state) => state.error,
    success: (state) => state.success,
    acapotdata: (state) => state.acapotdata,
    // return percentage of coffee pot volume
    volumePerc: (state) => {
      return (
        (state.acapotdata.scale.netto_kg / state.acapotdata.scale.brutto_kg) *
        100
      ).toFixed();
    },
    // return maximum volume of coffee pot
    maxVolume: (state) => {
      return state.acapotdata.scale.brutto_kg.toFixed(2);
    },
    // return current percentage of fillable cups
    cupsPerc: (state) => {
      var maxLiter = state.acapotdata.scale.brutto_kg * 100;
      var maxCups = maxLiter / 15;
      return ((state.acapotdata.coffee.cups_150ml / maxCups) * 100).toFixed();
    },
    // maximum fillable cups
    maxCups: (state) => {
      return ((state.acapotdata.scale.brutto_kg * 100) / 15).toFixed();
    },
    // assign every coffeeCounter entry to coffeeCounterData object
    coffeeCounterData: (state) => {
      state.coffeeCounterData;
    },
    // get coffeeCounter entries of logged in user
    myCoffeeCounterData: (state) => {
      // if user has uid get all entries that match uid and username of his
      if (state.user.uid[0]) {
        return state.coffeeCounterData.filter(
          (item) =>
            item.uid == state.user.uid[0] ||
            item.username == state.user.username
        );
      // if user has no uid get all entries that match this username
      } else {
        return state.coffeeCounterData.filter(
          (item) => item.username == state.user.username
        );
      }
    },
    //convert potAwayTime to minutes
    potAwayTime: (state) => {
      return (state.acapotdata.coffee.potAwayTime_sec / 60).toFixed();
    },
  },
  mutations: {
    // assign state user object with new user
    login_success(state, user) {
      state.user = user;
    },
    // assign error object of state to the error response message
    auth_error(state, err) {
      state.error = err.response.data.msg;
    },
    // assign success object of state to the register success response message
    register_success(state, msg) {
      state.success = msg;
    },
    // assign success object of state to the post coffee counter success response message
    postCoffeeCounter_success(state, msg) {
      state.success = msg;
    },
    // emptry user object and clear session storage
    logout(state) {
      state.user = { username: "", uid: [], id: Number };
      state.error = "";
      sessionStorage.clear();
    },
    // assign acapotdata object of state to the current acapotdata
    setAcaPotData(state, data) {
      state.acapotdata = data;
    },
    // assign coffee counter data of state to all entries of coffee counter
    setCoffeeCounterData(state, data) {
      state.coffeeCounterData = data;
    },
    // assign success object of state to the delete coffee counter success response message
    deleteCounter_success(state, msg) {
      state.success = msg;
    },
    // assign success object of state to the update user uid success response message
    updateUserUID_success(state,msg){
      state.success = msg;
    },
    // assign error object of state to the update user uid error response message
    updateUserUID_error(state,msg){
      state.error = msg;
    }
  },
  actions: {
    // implements api post request to signup as a user
    async register({ commit }, user) {
      this.state.success = "";
      this.state.error = "";
      try {
        let res = await axios.post("/users/register", user);
        if (res.data.success) {
          commit("register_success", res.data.msg);
        }
        return res;
      } catch (err) {
        commit("auth_error", err);
      }
    },
    // implements api post request to login as a user
    async login({ commit }, user) {
      this.state.success = "";
      this.state.error = "";
      try {
        let res = await axios.post("/users/login", user);
        let userData = res.data.user;
        if (res.data.success) {
          commit("login_success", userData);
        }
        return res;
      } catch (err) {
        commit("auth_error", err);
      }
    },
    // implements api put request to update uid of existing user
    async updateUser({ commit }, updatedUser) {
      this.state.success = "";
      this.state.error = "";
      try {
        let url = "/users/" + updatedUser.id;
        let res = await axios.put(url, updatedUser);
        if (res.data.success) {
          commit("updateUserUID_success", res.data.msg);
        }
        return res;
      } catch (err) {
        commit("updateUserUID_error", err);
      }
    },
    // logout as a user
    async logout({ commit }) {
      this.state.success = "";
      this.state.error = "";
      commit("logout");
    },
    // implements api post request to post new coffee counter entry
    async postCoffeeCounter({ commit }, potCounter) {
      this.state.success = "";
      this.state.error = "";
      try {
        let res = await axios.post("/coffeeCounter", potCounter);
        if (res.data.success) {
          commit("postCoffeeCounter_success", res.data.msg);
        }
        return res;
      } catch (err) {
        commit("postCoffeeCounter_error", err);
      }
    },
    // implements api delete request to delete coffee counter entry
    async deleteCoffeeCounter({ commit }, id) {
      this.state.success = "";
      this.state.error = "";
      try {
        let url = "/coffeeCounter/" + id;
        let res = await axios.delete(url);
        if (res.data.success) {
          commit("deleteCounter_success", res.data.msg);
        }
        return res;
      } catch (err) {
        commit("delete_error", err);
      }
    },
    // implements api get request to get all coffee counter entries
    loadCoffeeCounterData({ commit }) {
      let url = "/coffeeCounter";
      axios
        .get(url)
        .then((data) => data.data)
        .then((data) => commit("setCoffeeCounterData", data));
    },
    // implements api get request to get current acapotdata from local json file
    loadAcaPotData({ commit }) {
      let url = "acapotdata.json";
      axios
        .get(url)
        .then((data) => data.data)
        .then((data) => commit("setAcaPotData", data));
    },
  },
  modules: {},
});






















import Vue from "vue";
import { mapGetters } from "vuex";
import CoffeeCard from "./CoffeeCard.vue";

export default Vue.extend({
  name: "CoffeeCardData",
  data: () => ({}),
  components: {
    CoffeeCard,
  },
  computed: mapGetters([
    "acapotdata",
    "volumePerc",
    "cupsPerc",
    "maxVolume",
    "maxCups",
  ]),
});














































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import SignIn from "./auth/SignIn.vue";
import SignUp from "./auth/SignUp.vue";

export default Vue.extend({
  name: "Navbar",
  data: () => ({
    signInDialog: false,
    signUpDialog: false,
    loggedIn: false,
  }),
  components: {
    SignIn,
    SignUp,
  },
  computed: mapGetters(["user"]),
  methods: {
    ...mapActions(["logout"]),
    signOut() {
      this.logout();
    },
  },
});



















import Vue from "vue";
import store from "@/store";
import AcaPotSlideshow from "./AcaPotSlideshow.vue";
import CoffeeCardData from "./acapot-data/Card/CoffeeCardData.vue";
import CoffeeCounter from "./acapot-data/Counter/CoffeeCounter.vue";
import ProjectInfo from "./projectInfo.vue";

export default Vue.extend({
  name: "Main",

  // trigger vuex action to load acapot data and coffee counter data
  created() {
    store.dispatch("loadAcaPotData");
    store.dispatch("loadCoffeeCounterData");
  },

  data: () => ({}),
  components: {
    ProjectInfo,
    AcaPotSlideshow,
    CoffeeCardData,
    CoffeeCounter,
  },
});








































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import CoffeeCounterStatistic from "./CoffeeCounterStatistic.vue";

export default Vue.extend({
  name: "CoffeeCounter",
  data: () => ({
    revealStats: false,
    dialogChangeNFC: false,
    dialogAddNFC: false,
    uid: "",
  }),
  methods: {
    ...mapActions(["postCoffeeCounter", "updateUser"]),
    // update uid of user with new uid
    updateNFC() {
      let user = {
        username: this.$store.getters.user.username,
        uid: this.uid,
        id: this.$store.getters.user.id,
      };
      this.updateUser(user);
    },
    // post new coffee counter entry and reload web app
    addEntry() {
      let coffeeCounterData = {
        username: this.$store.getters.user.username,
        uid: this.$store.getters.user.uid,
        timestamp: new Date().getTime,
        method: "Button",
      };
      this.postCoffeeCounter(coffeeCounterData);
      location.reload();
    },
  },
  components: {
    CoffeeCounterStatistic,
  },
  computed: mapGetters(["user", "myCoffeeCounterData", "success", "error"]),
});
